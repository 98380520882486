import Aipower from '../Assests/images/aipower.png';
import Powerimg from '../Assests/images/powerimg.png';
import Interaction from '../Assests/images/interaction.jpg'
import Imacmockup from '../Assests/images/imac-mockup.png'
import Unsplash from '../Assests/images/unsplash.png'
import Keyimg from '../Assests/images/keyimg.png'
import Frame1 from '../Assests/images/frame1.png'
import Frame2 from '../Assests/images/frame2.png'
import Frame3 from '../Assests/images/frame3.png'
import Frame4 from '../Assests/images/frame4.png'
import Frame5 from '../Assests/images/frame5.png'
import Frame6 from '../Assests/images/frame6.png'
import Frame7 from '../Assests/images/frame7.png'
import Frame8 from '../Assests/images/frame8.png'
import Frame9 from '../Assests/images/frame9.png'
import Frame10 from '../Assests/images/frame10.png'
import Frame11 from '../Assests/images/frame11.png'
import Frame12 from '../Assests/images/frame12.png'

function ProductOne({ handleLoadPopup }) {

    return (
        <>

            <div className="herobanner">
                <div className="containercol">

                    <div className="flex align-items h70vh justify-content">
                        <div className="herocontent">
                            <h1>AI-Powered ShipperCopilot 6.4 for SAP S/4 HANA Cloud Public Edition 

                            </h1>
                            <h6> Seamless integration with all major parcel, LTL, TL, and FTL carriers. Quote, pick, pack, ship, and track—all in one AI-powered platform.

                            </h6>
                            <div className="cta">
                                <a href="#" className="ctabtn" onClick={handleLoadPopup}>Request Demo</a>
                            </div>
                        </div>
                        <div className="heroimg">
                            <img src={Aipower} alt="" />
                        </div>
                    </div>




                </div>
            </div>

            <div className="broucher">
                <div className="containercol">
                    <div className="flex gap10 items-center">
                        <div className='halfw'>
                            <img src={Powerimg} />
                        </div>
                        <div className='halfw'>
                            <h5>
                                We have the right multi-carrier outbound & inbound shipping software for SAP S/4HANA Cloud Private Edition
                            </h5>
                            <div className="cta">
                                <a href="#" className="ctabtn" onClick={handleLoadPopup}>Get Brochure Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="productslist">
                <div className="containercol">
                    <div className="flex">
                        <div className='halfw'>
                            <img src={Interaction} alt="" />
                        </div>
                        <div className='halfw'>
                            <ul className='productbrdr'>
                                <li> <strong>Seamless Carrier Integration</strong> Simplifies multi-carrier management by integrating with all major Parcel, LTL, TL, and FTL carriers, providing flexibility and cost-effective shipping options.
                                </li>
                                <li> <strong>AI-Powered Carrier & Route Optimization</strong> Utilizes advanced AI to optimize carrier selection and routing, reducing costs and transit times while enhancing delivery accuracy.
                                </li>
                                <li> <strong>Advanced Rate Shopping & Cost Optimization</strong> AI-driven rate comparison instantly finds the best shipping rates, including discounts, delivery speed, and destination, for efficient and budget-friendly shipping.
                                </li>
                                <li> <strong>Automated Address Validation & Denied Party Screening</strong> Ensures compliance and accuracy with AI-driven address validation and denied party screening, minimizing delivery errors and regulatory issues.
                                </li>
                                <li> <strong>Integrated RFID Technology</strong>Enhances inventory and asset tracking with RFID, providing real-time, automated visibility throughout the warehouse for efficient management.
                                </li>
                            </ul>


                        </div>
                        <div className='halfw'>
                            <ul className='productbrdr'>
                                <li> <strong>Seamless Carrier Integration</strong> Simplifies multi-carrier management by integrating with all major Parcel, LTL, TL, and FTL carriers, providing flexibility and cost-effective shipping options.
                                </li>
                                <li> <strong>AI-Powered Carrier & Route Optimization</strong> Utilizes advanced AI to optimize carrier selection and routing, reducing costs and transit times while enhancing delivery accuracy.
                                </li>
                                <li> <strong>Advanced Rate Shopping & Cost Optimization</strong> AI-driven rate comparison instantly finds the best shipping rates, including discounts, delivery speed, and destination, for efficient and budget-friendly shipping.
                                </li>
                                <li> <strong>Automated Address Validation & Denied Party Screening</strong> Ensures compliance and accuracy with AI-driven address validation and denied party screening, minimizing delivery errors and regulatory issues.
                                </li>
                                <li> <strong>Integrated RFID Technology</strong>Enhances inventory and asset tracking with RFID, providing real-time, automated visibility throughout the warehouse for efficient management.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="returns">
                <div className="container">
                    <div className='flex'>
                        <div className='halfw'>
                            <ul className='productbrdr'>
                                <li> <strong>Flexible Return Management System</strong> AI simplifies returns with automated label generation, real-time tracking, and customizable return options, improving customer experience.
                                </li>
                                <li> <strong>Hazmat/DG Goods Compliance</strong> Ensures safe and compliant handling of hazardous and DG (Dangerous Goods) shipments, meeting all international safety standards.

                                </li>
                                <li> <strong>Scalable & Configurable Platform</strong> Customizes workflows, carrier choices, and shipping configurations, allowing businesses to scale and adapt to evolving shipping needs.
                                </li>
                            </ul>
                        </div>
                        <div className='halfw'> </div>
                        <div className='halfw'>
                            <img src={Imacmockup} />
                        </div>
                    </div>

                </div>
            </div>

            <div className="returns">
                <div className="container">
                    <div className='flex'>

                        <div className='halfw'>
                            <img src={Unsplash} />
                        </div> <div className='halfw'> </div>
                        <div className='halfw'>
                            <ul className='productbrdr'>
                                <li> <strong> Enhanced Analytics & Reporting </strong> Delivers AI-powered insights and analytics on shipping costs, carrier performance, and delivery times, supporting data-driven improvements.
                                </li>
                                <li> <strong>Global Compliance with International Shipping Standards</strong> Handles all customs documentation, export/import compliance, and restricted goods regulations to ensure smooth, global shipping.


                                </li>

                            </ul>
                        </div>

                    </div>

                </div>
            </div>



            <div className="keybenfits">
                <div className="container">
                    <h3>
                        ShipperCopilot 6.4 for SAP S/4HANA <br /> Cloud Public Edition: Key Benefits</h3>

                    <div className='flex'>
                        <div className='halfw'>
                            <img src={Keyimg} />  </div>
                        <div className='halfw'>
                            <div className="blbg">

                                <ul className='productbrdr'>
                                    <li> <strong> AI-Driven Cost Optimization </strong>Saves shipping costs by up to 30% with intelligent multi-carrier selection and route optimization, powered by advanced AI rate shopping.

                                    </li>
                                    <li> <strong>Enhanced Visibility & Real-Time Tracking</strong> Provides full visibility from dispatch to delivery with real-time tracking, RFID-enabled inventory management, and automated updates for transparent, accurate logistics.

                                    </li>

                                    <li> <strong>Streamlined Compliance & Document Automation</strong> Simplifies compliance with automated documentation, including address validation, denied party screening, and hazardous goods handling, reducing manual tasks and errors.

                                    </li>
                                    <li> <strong>RFID Technology for Efficient Packing & Inventory Control</strong>Enhances packing and inventory accuracy with RFID technology, supporting efficient picking, packing, and real-time asset visibility for warehouse operations.


                                    </li> <li> <strong>Seamless Integration with SAP S/4HANA Cloud Private Edition, SAP EWM, and Multi-Carrier Shipping</strong> Provides robust integration with SAP S/4HANA Cloud Private Edition, SAP EWM, and major carrier systems for a scalable, end-to-end shipping and logistics solution adaptable to evolving business needs.



                                    </li>




                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="experts">
                <div className="containercol">

                    <h5>Get Started with ShipperCopilot 6.4 </h5>
                    <h6>Achieve up to 30% savings on operational <br /> and shipping costs.
                    </h6>
                    <div className="cta"><a href="#" onClick={handleLoadPopup} className="ctabtn">Get a live demo</a></div>

                </div>
            </div>


            <div className="featurescol">
                <div className="containercol">
                    <h3>Features</h3>

                    <ul className='framelist'>
                        <li>
                            <img src={Frame1} />
                            <div className="titlename">Rate Shop</div>
                            <em>Compare Carrier Rates Instantl</em>
                            <p>Efficiently compare rates across multiple carriers to select the most cost-effective option, optimizing your shipping costs.</p>

                        </li>
                        <li>
                            <img src={Frame2} />
                            <div className="titlename">Address Validation</div>
                            <em>Ensure Accurate Deliveries</em>
                            <p>Ensure Accurate Deliveries
                                Validate and standardize addresses in real-time to prevent delivery errors, enhancing overall customer satisfaction.</p>

                        </li>
                        <li>
                            <img src={Frame3} />
                            <div className="titlename">Denied Party Validation
                            </div>
                            <em>Ensure Compliance and Security</em>
                            <p>Screen shipping destinations against denied party lists to meet regulatory requirements and avoid penalties.</p>

                        </li>

                    </ul>

                    <ul className='framelist'>
                        <li>
                            <img src={Frame4} />
                            <div className="titlename">Export Filing
                            </div>
                            <em>Streamline Export Documentation </em>
                            <p>Automate the preparation and submission of required export documents, ensuring faster processing and compliance with export regulations.
                            </p>

                        </li>
                        <li>
                            <img src={Frame5} />
                            <div className="titlename">Ship </div>
                            <em>Manage Multi-Carrier Outbound Shipments</em>
                            <p> Simplify shipping across various carriers with centralized control, enhancing flexibility and delivery speed.</p>

                        </li>
                        <li>
                            <img src={Frame6} />
                            <div className="titlename">Barcode Plug-In
                            </div>
                            <em>Optimize Document Accuracy with Barcode Scanning </em>
                            <p>Integrate high-speed barcode scanning for error-free documentation, reducing manual input and expediting shipping processes.</p>

                        </li>

                    </ul>

                    <ul className='framelist'>
                        <li>
                            <img src={Frame7} />
                            <div className="titlename">RFID Plug-In</div>
                            <em>Boost Visibility and Control with RFID</em>
                            <p>Leverage RFID technology to track and manage inventory seamlessly, improving visibility and reducing stock inaccuracies.</p>

                        </li>
                        <li>
                            <img src={Frame8} />
                            <div className="titlename">Track</div>
                            <em>Monitor Shipments in Real-Time </em>
                            <p>Stay updated with live shipment tracking across carriers, providing end-to-end visibility from dispatch to delivery.
                            </p>

                        </li>
                        <li>
                            <img src={Frame9} />
                            <div className="titlename">RMA (Return Merchandise Authorization)
                            </div>
                            <em>Automate and Simplify Returns</em>
                            <p>Enable easy returns with pre-generated labels and tracking, simplifying the return process for both you and your customers.</p>

                        </li>

                    </ul>

                    <ul className='framelist'>
                        <li>
                            <img src={Frame10} />
                            <div className="titlename">DG/Hazmat Documentation</div>
                            <em>Ensure Safe Hazardous Material Shipping </em>
                            <p>Generate required documents for hazardous materials, ensuring compliance with regulatory standards for safe handling and transport.</p>

                        </li>
                        <li>
                            <img src={Frame11} />
                            <div className="titlename">Supplier/Carrier Portal</div>
                            <em>Streamline Supplier Collaboration </em>
                            <p>
                                Facilitate efficient inbound shipment coordination with suppliers, enhancing inventory management and reducing lead times.</p>

                        </li>
                        <li>
                            <img src={Frame12} />
                            <div className="titlename">Mobile App
                            </div>
                            <em>Manage Logistics On-the-Go</em>
                            <p>
                                Access shipping, tracking, and logistics management tools from any mobile device, providing flexibility and control from anywhere.
                            </p>

                        </li>

                    </ul>

                </div>
            </div>

        </>

    )

}

export default ProductOne