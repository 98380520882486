import React, { useEffect, useState, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import LandingPage from "./Pages/AiPowerShippingSuite/LandingPage";
import NewsEvents from "./Pages/News_Events/NewsEvents";
import Resources from "./Pages/Resources/Resources";
import Popupinfo from "./Components/Popupinfo";
import ProductOne from "./Products/ShipperCopilot6.4-SAP-S4HANA-Cloud-Public-Edition";
import Contactus from "./Pages/ContactUs/Contactus"
import Thankyou from "./Pages/Thankyou/Thankyou";
import ProductTwo from "./Products/ShipperCopilot6.4-SAP-S4HANA-Cloud-Private-Edition";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const handleLoadPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="App">
      {isOpen && (
        <Popupinfo ref={popupRef} closePopup={closePopup} />  
      )}
      <NavBar handleLoadPopup={handleLoadPopup} closePopup={closePopup} />
      <Routes>
        <Route
          path="/"
          element={<LandingPage handleLoadPopup={handleLoadPopup} />}
        />
        <Route
          path="/events"
          element={<NewsEvents handleLoadPopup={handleLoadPopup} />}
        />
        <Route path="/resources" element={<Resources />} />

        <Route path="/Products/ShipperCopilot6.4-SAP-S4HANA-Cloud-Public-Edition" element={<ProductOne handleLoadPopup={handleLoadPopup} />}  />

        <Route path="/Products/ShipperCopilot6.4-SAP-S4HANA-Cloud-Private-Edition" element={<ProductTwo handleLoadPopup={handleLoadPopup} />}  />

        <Route
          path="/contactus"
          element={<Contactus handleLoadPopup={handleLoadPopup} />}
        />
        <Route
          path="/thankyou"  
          element={<Thankyou handleLoadPopup={handleLoadPopup} />}
        />
      </Routes>
      <Footer handleLoadPopup={handleLoadPopup} />
    </div>
  );
}

export default App;
