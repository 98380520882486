import React from "react";
import EventEshipLogo from "../../Assests/Group 1321314419.png";
import EshipSiteLogo from "../../Assests/container-3-logo.png";
import EventParcelForum from "../../Assests/container-3-img.png";
import Heroimg from '../../Assests/images/partner.jpeg'
import "./NewsEvents.css";

function NewsEvents({handleLoadPopup}) {
  return (
    <>
      <div class="container-fluid News_evet_main_div">
        <div class="row" style={{ "background-color": "#28375E" }}>
          <div class="col d-flex flex-column justify-content-center custom-left">
            <h1 class="custom-title-2">News & Events with eshipjet.ai </h1>
            <p class="custom-para-2">
              Meet and connect with eshipjet warehouse and logistics experts,
              customers, partners and users through in-person or virtual events.
            </p>
            <button onClick={handleLoadPopup} class="btn btn-primary custom-btn-2 get-started">
              Talk to an expert{" "}
            </button>
          </div>
          <div class="col d-none d-md-flex align-items-center justify-content-center">
            <img class="news_event_img__" src={EventEshipLogo} alt="Image" />
          </div>
        </div>
      </div>

      <div class="container upcommingevents ">
        <h1 class="custom-title-3">Upcoming events</h1>
        <p class="custom-para-3">
          See highlights of upcoming eshipjet.ai events. You can sign up to stay
          notified.
        </p>
        <div class="row row-cols-1 row-cols-md-2 custom-row-3">
          <div class="col-md-12 mx-auto d-flex flex-column justify-content-between">
            <div class="card mb-4 h-100 text-center">
              <img
                src={EshipSiteLogo}
                class="custom-logo-3"
                alt="Image 1"
                width="195"
                height="195"
              />
              <img
                src={Heroimg}
                class=""
                alt="Image 2" 
              />
              <div class="card-body d-flex flex-column justify-content-between">
                
                <h5 class="card-title justify-content-between aligcent">Press Release</h5>
                <p class="card-text text-center">
                Discover the Future of SAP Logistics with ShipperCopilot 3.0 for SAP WM/EWM <br/>
                Discover How SAP ShipperCopilot 3.0 is Revolutionizing SAP Logistics

                  
                </p>
                <a href="https://register.gotowebinar.com/register/6852281151876749916" target="_blank" class="btn btn-primary custom-btn-3 mt-auto d-flex align-items-center justify-content-center">Learn more</a>
              </div>
            </div>
          </div>
 
         


        </div>
      </div>
    </>
  );
}

export default NewsEvents;
