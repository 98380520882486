import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Thankyou.css';

const Thankyou = ({closePopup}) => {
    const navigate = useNavigate();

    const handleClose = () => {
       
        navigate('/'); // Redirects to the home page
        
    };

    return (
        <div className='blubg'>
            <div className='blubgcontain'>
                <h4>Thank you! Our shipping experts <br/> will be in touch with you shortly.</h4>
                <button onClick={handleClose}>Close</button>
            </div>
        </div>
    );
};

export default Thankyou;
