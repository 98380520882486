import React, { useEffect, useState } from "react";
import "./NavBar.css";
import logo from "../Assests/logo.png";
import { IoMdMenu } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ProductPage from "./ProductPage";
import globe from "../Assests/globe.png";
import PhoneCall from "../Assests/images/phone.png";

const navigations = ["Products", "Events", "Resources", "Contact Us"];

function NavBar({ handleLoadPopup, closePopup }) {
  const [isMobile, setIsMobile] = useState(false);
  const [product, setProduct] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleclickedmenue = () => {
    setIsMobile(!isMobile);
    setProduct(false);
    closePopup();
  };

  const handleNavigationClick = (item) => {
    if (item === "Products") {
      setProduct(!product);
      closePopup(); // Close the popup when the logo is clicked
      if (window.innerWidth < 768) {
        setIsMobile(true);
      }
    }
    if (item === "Events") {
      setProduct(false);
      navigate("/events");
      closePopup(); // Close the popup when the logo is clicked
      if (window.innerWidth < 768) {
        setIsMobile(true);
      }
    }
    if (item === "Resources") {
      setProduct(false);
      navigate("/resources");
      closePopup(); // Close the popup when the logo is clicked
      if (window.innerWidth < 768) {
        setIsMobile(true);
      }
    }
    if (item === "Contact Us") {
      setProduct(false);
      navigate("/contactus");
      closePopup(); // Close the popup when the logo is clicked
      if (window.innerWidth < 768) {
        setIsMobile(true);
      }
    }
  };

  const handleCall = () => {
    const phoneNumber = "+18884642360";
    window.location.href = `tel:${phoneNumber}`;
    closePopup(); // Close the popup when the logo is clicked
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  };

  const handleclicklogo = () => {
    navigate("/");
    setProduct(false);
    closePopup(); // Close the popup when the logo is clicked
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  };

  const closeProduct = () => {
    setProduct(false);
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  };

  return (
    <header className="header">
      <div className="containercol">
        <div className="flex justify-content align-items">
          <div className="logo">
            <img src={logo} alt="logo" onClick={handleclicklogo} />
          </div>
          {!isMobile && (
            <>
              <div className="menu">
                <ul className="flex align-items">
                  {navigations.map((items, index) => (
                    <li key={index} onClick={() => handleNavigationClick(items)}>
                      {items}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="cta">
                <ul className="flex align-items">
                  <li className="phone">
                    <a href="#" onClick={handleCall}>
                      <img src={PhoneCall} alt="" /> +1 (888) 464-2360
                    </a>
                  </li>
                  <li className="getlink">
                    <a href="#" onClick={() => { closeProduct(); handleLoadPopup(); }}>
                      Get a live demo
                    </a>
                  </li>
                  <li className="language">
                    <img src={globe} alt="globe" />
                  </li>
                </ul>
              </div>
            </>
          )}
          <IoMdMenu className="togggle_bar_div" onClick={handleclickedmenue} size={30} />
        </div>
      </div>
      {product && (
        <div>
          <ProductPage handleLoadPopup={handleLoadPopup} closeProduct={closeProduct} />
        </div>
      )}
    </header>
  );
}

export default NavBar;
