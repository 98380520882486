import React from "react";
import "./Footer.css";
import lookto from "../Assests/lookto.png";
import link from "../Assests/link.png";
import twi from "../Assests/twi.png";
import inst from "../Assests/inst.png";
import you from "../Assests/you.png";
import mes from "../Assests/mes.png";
import call from "../Assests/call.png";


function Footer({handleLoadPopup}) {
  const footerdata = [
    {
      header: "Germany Headquarters",
      company: "eshipJet Software GmbH",
      address: "Schlossweg 4 69190 Walldorf",
      phone: "Phone: +49 17630005612",
      email: "Email: europe@eshipjet.ai",
    },
    {
      header: "USA Headquarters",
      company: "eshipJet Software Inc.",
      address:
        " 5717 Legacy Dr Suite 250 Plano, TX 75024",
      phone: "Phone: (888) 464-2360",
      email: "Email: info@eshipjet.ai",
    },
    {
      header: "India Headquarters",
      company: "eshipJet Software private limited.",
      address: "Capital Pk Road, Hitech City, Hyderabad, TG 500081" ,
         
      phone: "Phone: +91 915 492 7351",
      email: "Email: info@eshipjet.ai",
    },
    {
      header: "San Jose",
      company: "eshipJet Software Inc.",
      address:
        " 188 The Embarcadero, Suite 200 San Jose, CA 95113",
      phone: "Phone: (888) 464-2360",
      email: "Email: info@eshipjet.ai",
    },
    {
      header: "Atlanta",
      company: "eshipJet Software Inc.",
      address:
        " 1740 Grassland Parkway, Suite 303 Alpharetta, GA 30004",
      phone: "Phone: (888) 464-2360",
      email: "Email: info@eshipjet.ai",
    },
  ];

  // Array of icon objects with id and src
  const icons = [
    { id: 1, src: link },
    { id: 2, src: twi },
    { id: 3, src: inst },
    { id: 4, src: you },
    { id: 5, src: mes },
    { id: 6, src: call },
  ];

  // Function to handle icon clicks

  const handleClickedIcon = (id) => {
    if (id == 1) {
      window.location.href = `https://www.linkedin.com/company/eshipjet/`;
    }
    if (id == 2) {
      window.location.href = ` https://x.com/eshipjet`;
    }
    if (id == 3) {
      window.location.href = `https://www.instagram.com/eshipjet/`;
    }
    if (id == 4) {
      window.location.href = `https://www.youtube.com/@eshipjet`;
    }
    if (id == 5) {
      window.location.href = `https://www.youtube.com/@eshipjet`;
    }
  };

  return (
    <div className="footer_main_div_parent d-flex flex-column">
 

      <div className="shipping">

<div className="containercol">
    <div className="flex">
        <h5>Looking to enhance your shipping process?</h5>
        <div className="cta">
            <a href="#" onClick={handleLoadPopup}>Contact Sales</a>
        </div>
    </div>
</div>


</div>


      <div className="d-flex flex-column flex-md-row address_grid_main_footer">
        {footerdata.map((item, index) => (
          <div key={index} className="footer_address_boxes d-flex flex-column">
            <span className="address_header_footer">{item.header}</span>
            <span className="address_text_footer">{item.company}</span>
            <span className="address_text_footer">{item.address}</span>
            <span className="address_text_footer">{item.phone}</span>
            <span className="address_text_footer">{item.email}</span>
          </div>
        ))}
      </div>
      <div className="footer_icons_text_holder d-flex flex-column flex-md-row align-items-center">
        <div className="d-flex icons_footer_parent_holder">
          {icons.map((item) => (
            <img
              key={item.id}
              className="icons_footer_img"
              src={item.src}
              alt="Social icon"
              onClick={() => handleClickedIcon(item.id)}
            />
          ))}
        </div>
        <span className="copy_rights_footer">
          Copyright © 2024 eshipjet Inc. All rights reserved.
        </span>
        <span className="copy_rights_privacy">Privacy</span>
        <span className="copy_rights_states">United States</span>
      </div>
    </div>
  );
}

export default Footer;
