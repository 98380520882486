import React from "react";
import "./Product.css";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
 
const ProductPage = ({ closeProduct, handleLoadPopup }) => {
  return (
    <div className="product_top_main_div d-flex flex-column justify-content-start">
    <div className="closebtn_productpage">
        <IoIosClose style={{ height: '100%', width: '100%' }} onClick={closeProduct} />
      </div>
    <div className="product_card_holder">
        <h2 className="product_head">Outbound Solutions - ShipperCopilot 3.0</h2>
        <div className="d-flex">
            <div className="product_subdescription d-flex flex-column">
                <div> <Link to="/Products/ShipperCopilot6.4-SAP-S4HANA-Cloud-Public-Edition" className="product_item" href="#" onClick={closeProduct}>SAP S/4HANA
                        Public Edition with ShipperCopilot AI</Link></div>

                <div> <Link to="/Products/ShipperCopilot6.4-SAP-S4HANA-Cloud-Private-Edition" className="product_item" onClick={closeProduct}>SAP S/4HANA
                        Private Edition with ShipperCopilot AI</Link></div>
                        
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item"
                        href="#">Microsoft Dynamics 365
                        with ShipperCopilot AI</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item"
                        href="#">Oracle Fusion Cloud
                        ERP with ShipperCopilot AI</Link></div>
            </div>
            <div className="product_subdescription d-flex flex-column">
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem"
                        href="#">Outbound
                        Multi-Carrier Shipping with ShipperCopilot AI</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem" href="#">NetSuite ERP
                        with ShipperCopilot AI</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem" href="#">Sage X3 with
                        ShipperCopilot AI</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem" href="#">Infor M3 with
                        ShipperCopilot AI</Link></div>
            </div>
        </div>
    </div>
    <div className="product_card_holder">
        <h2 className="product_head">Inbound Solutions – iMayl 9.0</h2>
        <div className="d-flex">
            <div className="product_subdescription d-flex flex-column">
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item"
                        href="#">Corporate Mailroom
                        Management by iMayl AI-Powered</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item"
                        href="#">Hospitals
                        &amp; Healthcare Management by iMayl AI-Powered</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item"
                        href="#">Financial
                        Institutions Management by iMayl AI-Powered</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item" href="#">Property
                        Management by iMayl AI-Powered</Link></div>
            </div>
            <div className="product_subdescription d-flex flex-column">
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem"
                        href="#">Schools &amp;
                        Government Management by iMayl AI-Powered</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem"
                        href="#">Hospitality Management by
                        iMayl AI-Powered</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem"
                        href="#">Pharmaceutical
                        Companies Management by iMayl AI-Powered</Link></div>
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem"
                        href="#">Inbound
                        Supplier &amp; Carrier Portal by iMayl AI-Powered</Link></div>
            </div>
        </div>
    </div>
    <div className="product_card_holder">
        <h2 className="product_head">RFID Technologies – RFID Nexus 6.4</h2>
        <div className="d-flex">
            <div className="product_subdescription d-flex flex-column">
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_item"
                        href="#">RFID
                        tracking for logistics and shipping by RFID Next Pro 6.4</Link></div>
            </div>
            <div className="product_subdescription d-flex flex-column">
                <div> <Link onClick={() => { closeProduct(); handleLoadPopup(); }}  className="product_subitem"
                        href="#">RFID Inventory
                        Management by RFID Next Edge 6.4</Link></div>
            </div>
        </div>
    </div>
</div>
  );
};

export default ProductPage;
