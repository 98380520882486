import React from 'react'
import './LandingPage.css'
import LandingpageBody from './LandingpageBody'
import LandingBodyMain from './LandingBodyMain'

function LandingPage({handleLoadPopup}) {
  return (
    <div className='LandingPage_main_parent_div d-flex flex-column'>
       
        <LandingBodyMain handleLoadPopup={handleLoadPopup}/>
    </div>
  )
}

export default LandingPage