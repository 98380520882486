
import React from "react";
import Heroimg from '../../Assests/images/heroimg.png'
import Client1 from '../../Assests/images/client1.png'
import Client2 from '../../Assests/images/client2.png'
import Client3 from '../../Assests/images/client3.png'
import Client4 from '../../Assests/images/client4.png'
import Client5 from '../../Assests/images/client5.png'
import Client6 from '../../Assests/images/client6.png'
import Client7 from '../../Assests/images/client7.png'
import Client8 from '../../Assests/images/client8.png'
import Client9 from '../../Assests/images/client9.png'
import Erp from '../../Assests/images/erp.png'
import Pilotimg from '../../Assests/images/pilotimg.png'
import Imac from '../../Assests/images/imac.png'
import Shipperbox from '../../Assests/images/shipperbox.png'
import Pilotone from '../../Assests/images/pilotone.png'
import Pilotwo from '../../Assests/images/pilotwo.png'
import Rifdone from '../../Assests/images/rfidone.png'
import Rfidtwo from '../../Assests/images/rfidtwo.png'
import Imayl from '../../Assests/images/imayl.png'

function LandingBodyMain({ handleLoadPopup }) {

    return (<> 


        <div className="herobanner">
            <div className="containercol">

                <div className="flex align-items h70vh justify-content">
                    <div className="herocontent">
                        <h1>AI-Powered Multi-carrier Logistics Software for Outbound &amp; Inbound
                        </h1>
                        <h6>Streamline your entire logistics process with advanced AI-powered software. Manage multi-carrier shipping
                            for both outbound and inbound operations seamlessly.
                        </h6>
                        <div className="cta">
                            <a href="#" onClick={handleLoadPopup} className="ctabtn">Explore ShipperCopilot 6.4</a>
                        </div>
                    </div>
                    <div className="heroimg">
                        <img src={Heroimg} alt="ShipperCopilot" title="ShipperCopilot" />
                       
                        
                    </div>
                </div>




            </div>
        </div>

        <div className="clients">
            <div className="containercol">
                <ul className="flex align-items justify-content">
                    <li><a href="#"> <img src={Client1} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client2} alt="" />  </a></li>
                    <li><a href="#"> <img src={Client3} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client4} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client5} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client6} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client7} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client8} alt="" /> </a></li>
                    <li><a href="#"> <img src={Client9} alt="" /> </a></li>
                </ul>
            </div>
        </div>


        <div className="erprow">
            <div className="containercol">
                <h2>Unified, Integrated ERP &amp; E-Commerce Solution</h2>

                <div className="flex">
                    <div className="erpimg">
                        <img src={Erp} alt="" />
                    </div>
                    <div className="erpcnt">
                        <h3>All-in-One Multi-Carrier Shipping </h3>
                        <ul>
                            <li>Quote: Compare carrier rates instantly.</li>
                            <li>Book: Reserve shipments seamlessly.</li>
                            <li>Ship: Automate label generation and carrier integration.</li>
                            <li>Deliver: Ensure timely deliveries with real time visibility.</li>
                            <li>Track: Monitor shipments end-to-end with live updates</li>
                        </ul>
                    </div>

                </div>


            </div>
        </div>

        <div className="experts">
            <div className="containercol">
               
                    <h5>Get Started with eshipjet.ai </h5>
                    <h6>ShipperCopilot at work</h6>
                    <div className="cta"><a href="#" onClick={handleLoadPopup} className="ctabtn">Learn More</a></div>
                
            </div>
        </div>


        <div className="copilotrow">
            <div className="containercol">

                <div className="copilot">

                    <h4>ShipperCopilot suite 6.4</h4>
                    <div className="flex">
                        <div className="copilotimg">
                            <img src={Pilotimg} alt="" />
                        </div>
                        <div className="copilotinfo">
                            <ul>
                                <li>
                                    <strong>Rate Shop</strong>
                                    Compare shipping rates across carriers.
                                </li>
                                <li>
                                    <strong>Denied Party Validation</strong>
                                    Verify compliance with denied party lists.

                                </li>
                                <li>
                                    <strong>Ship</strong>
                                    Manage multi-carrier outbound <br /> 
                                    shipping.
                                </li>
                                <li>
                                    <strong>Track</strong>
                                    Real-time shipment tracking across carriers.

                                </li>
                                <li>
                                    <strong>DG/Hazmat Documents</strong>
                                    Safely ship hazardous<br />  materials.
                                </li>
                                <li>
                                    <strong>Mobile App</strong>
                                    Ship and manage logistics via mobile.
                                </li>

                            </ul>


                            <ul>
                                <li>
                                    <strong>Address Validation</strong>
                                    Ensure accurate delivery addresses.
                                </li>
                                <li>
                                    <strong>Export Filling</strong>
                                    Automate export document submissions.
                                </li>
                                <li>
                                    <strong>Barcode Plug-In</strong>
                                    Fast, accurate barcode capabilities for shipping documents.
                                </li>
                                <li>
                                    <strong>RMA </strong>
                                    Automate returns with labels and tracking.

                                </li>
                                <li>
                                    <strong>Supplier/Carrier Portal</strong>
                                    Collaborate with suppliers for inbound shipments.
                                </li>
                                <li>
                                    <strong>RFID Plug-In  </strong>Manage your inventory.
                                </li>

                            </ul>



                        </div>
                    </div>
                </div>

                <div className="infoimgs">
                    <div className="flex">
                        <div className="infoimgone">
                            <img src={Pilotone} alt="" />
                        </div>
                        <div className="infoimgtwo">
                            <img src={Pilotwo} alt="" />
                        </div>
                    </div>
                </div>


                <div className="infoboxs">
                    <div className="infocol infoerp">
                        <h6>Seamless integration with all major ERP systems:
                        </h6>
                        <ul>
                            <li>
                                SAP S/4HANA Cloud (Private/Public Edition), SAP EWM</li>
                            <li>
                                Oracle Fusion Cloud</li>
                            <li>
                                Microsoft Dynamics 365</li>
                            <li>
                                Infor M3</li>
                            <li>
                                Sage</li>
                            <li>
                                NetSuite and more.</li>
                        </ul>

                    </div>
                    <div className="infocol infowms">
                        <h6>Seamless integration with all major WMS 
systems:

                        </h6>
                        <ul>
                     <li>   SAP EWM</li>

                     <li>   Blue Yonder (JDA)</li>

                     <li>   Manhattan Associates</li>

                     <li>   HighJump (Korber)</li>

                     <li>   Oracle WMS Cloud</li>

                     <li>   Infor SCM, Tecsys, Fishbowl</li>

                        </ul>

                    </div>
                    <div className="infocol infocom">
                        <h6>Seamless integration with 

                        </h6>
                        <ul>
                            <li>
                            eCommerce Platforms:


</li> 
                        </ul>
                        <p>Shopify , Magento, BigCommerce <br /> 

                        WooCommerce, Salesforce Commerce Cloud.</p>
<ul>
    <li>Marketplaces:
    </li>
</ul>
<p>Amazon, eBay, Walmart Marketplace, Alibaba, Etsy
</p>
                    </div>
                </div>



            </div>
        </div>


        <div className="shippercopilot">
            <div className="containercol">
                <h3>ShipperCopilot 6.4</h3>

                <div className="shipperow">
                    <div className="shipcol">
                        <ul>
                            <li>Route Optimization</li>
                            <li>Multi-Carrier Support</li>
                            <li>White Glove Service Integration</li>
                            <li>Label Printing </li>
                        </ul>
                        <div className="shpperimg"> <img src={Imac} alt="" /> </div>
                        <ul>
                            <li> Customs and Duties Management  </li>
                            <li>Freight Consolidation  </li>
                            <li>AI-Powered Analytics
                        </li>
                            <li>Carrier Rate Negotiation

                            </li>
                        </ul>
                    </div>
                    <div className="shipcol">
                        <ul>
                            <li>ERP Integration  </li>
                            <li>LTL, TL, FTL, Parcel Support </li>
                            <li>Document Management  </li>
                            <li>Compliance Management </li>
                        </ul>
                        <div className="shpperimg">
                            <img src={Shipperbox} alt="" /></div>
                        <ul>
                            <li>Dynamic Notifications  </li>
                            <li>Warehouse Integration</li>
                            <li>Customizable Dashboards</li>
                            <li>24/7 Support  </li>
                        </ul>
                    </div>
                </div>



            </div>
        </div>

        <div className="rfidrow">
            <div className="containercol">
                <div className="flex">
                    <div className="rfidleft">
                        <img src={Rifdone} alt="" className="rifdimg" />
                        <img src={Rfidtwo} alt="" className="machineimg" />
                    </div>
                    <div className="rfidright">
                        <h4>RFID Nexus 6.4</h4>
                        <p>RFID Nexus 6.4 integrates with:</p>
                        <ul>
                            <li>
                                All major Warehouse Management Systems (WMS) </li>
                            <li>


                                Real-Time Inventory Tracking</li>
                            <li>


                                RFID Tagging and Scanning</li>
                            <li>


                                Asset Tracking and Management</li>

                        </ul>
                    </div>
                </div>


            </div>
        </div>


        <div className="imacol">
            <div className="containercol">
                <h3>Imayl 9.0</h3>

                <div className="flex">
                    <div className="imaone">
                        <ul className="left">
                            <li>AI-OCR&nbsp;for&nbsp;Label&nbsp;Scanning</li>
                            <li>Chain&nbsp;of&nbsp;Custody&nbsp;Management</li>
                            <li>Real-Time&nbsp;Shipment&nbsp;Visibility</li>
                            <li>Real-Time&nbsp;Notifications</li>
                            <li>Audit&nbsp;Trail&nbsp;and&nbsp;Compliance</li>
                            <li>Offline&nbsp;Processing&nbsp;Support</li>
                            <li>Automated&nbsp;Receiving&nbsp;Workflows</li>
                            <li>Intelligent Package Tracking and Routing Optimization</li>
                        </ul>

                        <ul className="right">
                            <li>Multi-Location&nbsp;Package&nbsp;Management</li>
                            <li>Smart&nbsp;Locker&nbsp;Integration</li>
                            <li>Digital&nbsp;Signature&nbsp;Capture</li>
                            <li>Automated&nbsp;Package&nbsp;Sorting</li>
                            <li>Storage&nbsp;and&nbsp;Bin&nbsp;Management</li>
                            <li>Mobile&nbsp;App&nbsp;Support</li>
                            <li>Carrier&nbsp;Integration</li>
                            <li>Photo&nbsp;Capture&nbsp;Upon&nbsp;Delivery</li>
                        </ul>
                    </div>
                    <div className="imatwo">
                        <img src={Imayl} alt="" />
                        <h5>Streamlining Inbound Package <br />
                            Management with Real-Time Visibility
                        </h5>
                    </div>
                </div>


            </div>
        </div>



    </>
    )
}

export default LandingBodyMain