import React, { useEffect, useRef, useState } from "react";
import "./LandingPage.css";
import LandingPagetop from "../../Assests/LandingPagetop.png";
import fedex from "../../Assests/fedex.png";
import ups from "../../Assests/ups.png";
import usps from "../../Assests/usps.png";
import dhl from "../../Assests/dhl.png";
import cpc from "../../Assests/cpc.png";
import ontrac from "../../Assests/ontrac.png";
import pur from "../../Assests/pur.png";
import gls from "../../Assests/gls.png";
import ap from "../../Assests/ap.png";
import eshipquote from "../../Assests/eshipquote.png";
import rfidnexus from "../../Assests/rfidnexus.png";
import inbound from "../../Assests/inbound.png";
import copilotsuite from "../../Assests/copilotsuite.png";
import eshipimg from "../../Assests/eshipimg.png";
import ehsiptwo from "../../Assests/ehsiptwo.png";
import copilotbg from "../../Assests/copilotbg.png";
import coipad1 from "../../Assests/coipad1.png";
import copimgtwo from "../../Assests/copimgtwo.png";
import suitbg from "../../Assests/suitbg.png";
import scanrfid from "../../Assests/scanrfid.png";
import rfidbg from "../../Assests/rfidbg.png";

const shippingicons = [fedex, ups, usps, dhl, cpc, ontrac, pur, gls, ap];

const data = {
  Quote: "Compare carrier rates instantly.",
  Book: "Reserve shipments seamlessly.",
  Ship: "Automate label generation and carrier integration.",
  Deliver: "Ensure timely deliveries with real-time visibility.",
  Track: "Monitor shipments end-to-end with live updates.",
};

const imayldata = [
  {
    items: [
      { text: "AI-OCR for Label Scanning", color: "#0C5" },
      { text: "Chain of Custody Management", color: "#98AC00" },
      { text: "Real-Time Shipment Visibility", color: "#B02A1E" },
      { text: "Real-Time Notifications", color: "#C31CA5" },
      { text: "Audit Trail and Compliance", color: "#81AC5A" },
      { text: "Offline Processing Support", color: "#7D1162" },
      { text: "Automated Receiving Workflows", color: "#5F84FF" },
      {
        text: "Intelligent Package Tracking and Routing Optimization",
        color: "#0C5",
      },
    ],
  },
  {
    items: [
      { text: "Multi-Location Package Management", color: "#5F84FF" },
      { text: "Smart Locker Integration", color: "#7D1162" },
      { text: "Digital Signature Capture", color: "#81AC5A" },
      { text: "Automated Package Sorting", color: "#C31CA5" },
      { text: "Storage and Bin Management", color: "#B02A1E" },
      { text: "Mobile App Support", color: "#98AC00" },
      { text: "Carrier Integration", color: "#022C4D" },
      { text: "Photo Capture Upon Delivery", color: "#2CC6B9" },
      
    ],
  },
];

const rfidData = {
  title: "RFID Nexus 6.4",
  integrations: [
    "All major Warehouse Management Systems (WMS)",
    "Real-Time Inventory Tracking",
    "RFID Tagging and Scanning",
    "Asset Tracking and Management",
  ],
};

const shippercopilotsuitedata = [
  {
    features: [
      {
        id: 1,
        title: "Rate Shop",
        description: "Compare shipping rates across carriers.",
      },
      {
        id: 2,
        title: "Address Validation",
        description: "Ensure accurate delivery addresses.",
      },
      {
        id: 3,
        title: "Denied Party Validation",
        description: "Verify compliance with denied party lists.",
      },
      {
        id: 4,
        title: "Export Filling",
        description: "Automate export document submissions.",
      },
      {
        id: 5,
        title: "Ship",
        description: "Manage multi-carrier outbound shipping.",
      },
      {
        id: 6,
        title: "Barcode Plug-In",
        description:
          "Fast, accurate barcode capabilities for shipping documents.",
      },
      {
        id: 7,
        title: "Track",
        description: "Real-time shipment tracking across carriers.",
      },
      {
        id: 8,
        title: "RMA",
        description: "Automate returns with labels and tracking.",
      },
      {
        id: 9,
        title: "DG/Hazmat Documents",
        description: "Safely ship hazardous materials.",
      },
      {
        id: 10,
        title: "Supplier/Carrier Portal",
        description: "Collaborate with suppliers for inbound shipments.",
      },
      {
        id: 11,
        title: "Mobile App",
        description: "Ship and manage logistics via mobile.",
      },
      {
        id: 12,
        title: "RFID Plug-In",
        description: "Manage your inventory.",
      },
    ],
  },
];

const eshipjetaiboxone = [
  {
    head: "Integrates with All Major Parcel Carriers ",
    des: "Supports FedEx, UPS, DHL, and USPS.",
  },
  {
    head: "Regional Carriers ",
    des: "Supports Deliver-It, OnTrac,  LaserShip, and Spee-Dee.",
  },
  {
    head: "International Carriers",
    des: "Integrates with services like TNT, Aramex, and DPD.",
  },
];

const copilotb1 = [
  { text: "Route Optimization" },
  { text: "Multi-Carrier Support" },
  { text: "White Glove Service Integration" },
  { text: "Label Printing" },
  { img: coipad1 },
  { text: "ERP Integration" },
  { text: "LTL, TL, FTL, Parcel Support" },
  { text: "Document Management" },
  { text: "Compliance Management" },
];

const copilotbox3 = [
  { text: "Customs and Duties Management" },
  { text: "Freight Consolidation" },
  { text: "AI-Powered Analytics" },
  { text: "Carrier Rate Negotiation" },
  { img: copimgtwo },
  { text: "Dynamic Notifications" },
  { text: "Warehouse Integration" },
  { text: "Customizable Dashboards" },
  { text: "24/7 Support" },
];

const TopPagemain = ({ handleLoadPopup }) => {
  return (
    <>
      <div className="landingpage_body_main_div d-flex align-items-center">
        <div className="landing_page_top_text_image_parent d-flex flex-column flex-md-row justify-content-start align-items-center">
          <div className="ai_powered_multi_carrier d-flex flex-column">
            <span className="main_text">
              AI-Powered Multi-carrier Logistics Software for Outbound & Inbound
            </span>
            <span className="sub_text">
              Streamline your entire logistics process with advanced AI-powered
              software. Manage multi-carrier shipping for both outbound and
              inbound operations seamlessly.
            </span>
            <button className="talk_to_an_expert" onClick={handleLoadPopup}>
              Explore ShipperCopilot 6.4
            </button>
          </div>
          <div>
            <img
              className="top_image_div_landing_page"
              src={LandingPagetop}
              alt="Landing Page Top"
            />
          </div>
        </div>
      </div>
      <div className="shipping_icons_parent_div d-flex flex-wrap">
        {shippingicons.map((item, index) => (
          <img
            key={index}
            className="images_icons_fedex"
            src={item}
            alt="Shipping Icon"
          />
        ))}
      </div>
    </>
  );
};

const Multicarries = () => {
  return (
    <>
      <div className="unified_text_tag d-flex justify-content-center justify-content-md-start">
        <span>Unified, Integrated ERP & E-Commerce Solution</span>
      </div>
      <div
        id="scrollidtwo"
        className="landing_page_second_part d-flex flex-column flex-md-row"
      >
        <img className="ehsip_quote_image_tag" src={eshipquote} />
        <div className="ehsip_quote_image_tag_text_parent d-flex flex-column align-items-start">
          <span className="Multi-Carrier_text">
            All-in-One Multi-Carrier Shipping
          </span>
          <div>
            {Object.entries(data).map(([key, value]) => (
              <div className="d-flex multicarrer_sub_text" key={key}>
                <span className="d-flex multicarrer_sub_text_key">
                  <li></li> {key} :
                </span>
                <span className="d-flex multicarrer_sub_text_value">
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const GetstartwithEship = ({ handleLoadPopup }) => {
  return (
    <div className="getstart_with_eship-parent_div d-flex justify-content-center">
      <div className="getstart_with_eship-parent_div_sub_tag d-flex align-items-center justify-content-center flex-column flex-md-row">
        <span>
          Get Started with eshipjet.ai All major carriers are fully integrated
        </span>
        <buton onClick={handleLoadPopup} className="speak_to_expert">
          Speak to Experts
        </buton>
      </div>
    </div>
  );
};

const ShipperCopilotSuitev = () => {
  return (
    <>
      <div
        id="scrollidthree"
        className="ShipperCopilotSuite_main_div d-flex flex-column align-items-center"
        style={{ backgroundImage: `url(${suitbg})` }}
      >
        <div className="ShipperCopilotSuite_main_div_heading d-flex justify-content-md-start justify-content-center ">
          <span>ShipperCopilot suite</span>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <img className="copilot_suite_image" src={copilotsuite} />
          <div>
            <div>
              {shippercopilotsuitedata.map((item, index) => (
                <div
                  className="d-flex flex-wrap shipper_copilot_suite_text_holder"
                  key={index}
                >
                  {item.features.map((feature, featureIndex) => (
                    <div className="d-flex align-items-start shipper_copilot_suite_text_holder_sub_div">
                      <span className="shipper_copilot_suite_tex_id">
                        {/* {feature.id} */}
                      </span>
                      <div className="d-flex flex-column shipper_copilot_suite_text_parent_box ">
                        <span className="shipper_copilot_suite_tex_title">
                          {feature.title}
                        </span>
                        <span className="shipper_copilot_suite_tex_description">
                          {feature.description}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Eshipjetai = () => {
  return (
    <>
      <div className="eshipjes_ai_comp_parent d-flex flex-column">
        <div className="eshipimg_eshipjet_comp_parent_div_tag d-flex flex-column flex-md-row align-items-center">
          <img className="eshipimg_eshipjet_comp" src={eshipimg} />
          <img className="eshipimg_eshipjet_comp_img_two" src={ehsiptwo} />
        </div>
        <div className="eshipjet_comp_boxs_holder_parent d-flex flex-column flex-md-row align-items-center">
          <div className="eshipjes_ai_comp_box1 d-flex flex-column">
            <span>Seamless integration with all major ERP systems:-</span>
            <li>SAP S/4HANA Cloud (Private/Public Edition), </li>
            <li>SAP EWM</li>
            <li>Oracle Fusion Cloud</li>
            <li>Microsoft Dynamics 365</li>
            <li>Infor M3</li>
            <li>Sage</li>
            <li>NetSuite and more.</li>
          </div>

          <div className="eshipjes_ai_comp_box2 d-flex flex-column">
            <span>Seamless integration with all major WMS systems:</span>
            <li>SAP EWM</li>
            <li>Blue Yonder (JDA)</li>
            <li>Manhattan Associates </li>
            <li>HighJump (Korber)</li>
            <li>Oracle WMS Cloud</li>
            <li>Infor SCM, Tecsys, Fishbowl</li>
          </div>

          <div className="eshipjes_ai_comp_box3 d-flex flex-column">
            <span>Seamless integration with </span>
            <li>eCommerce Platforms:</li>
            <p>
              Shopify , Magento, BigCommerce WooCommerce, Salesforce Commerce
              Cloud.
            </p>
            <li>Marketplaces:</li>
            <p>Amazon, eBay, Walmart Marketplace, Alibaba, Etsy</p>
          </div>
        </div>
      </div>
    </>
  );
};

const Rfidnexus = () => {
  return (
    <div className="rfid_nexus_parent_div">
      <div
        style={{ backgroundImage: `url(${rfidbg})` }}
        className="rfidpage_Parent_part d-flex flex-column flex-md-row align-items-center justify-content-md-end"
      >
        <img
          className="rfid_nexus_scanner_image"
          src={scanrfid}
          alt="RFID Nexus"
        />
        <img className="rfid_nexus_image" src={rfidnexus} alt="RFID Nexus" />
        <div className="rfid_quote_image_tag_text_parent d-flex flex-column align-items-start">
          <span className="rfid_nexus_heading">{rfidData.title}</span>
          <span className="rfid_integrates_heading">
            {rfidData.title} integrates with:
          </span>
          {rfidData.integrations.map((integration, index) => (
            <li key={index} className="rfit_sub_text_integrates">
              {integration}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

const CopilotSix = () => {
  return (
    <div
      id="scrollidfour"
      className="copilot_parent-main_div d-flex flex-column align-items-start"
      style={{ backgroundImage: `url(${copilotbg})` }}
    >
      <span
        className="Imayl_heading_div"
        style={{ color: "rgba(13, 13, 13, 1)" }}
      >
        ShipperCopilot 6.4
      </span>
      <div className="copilot_parent_to_sub_div d-flex flex-column flex-md-row">
        <div className="copilot_parent_sub_div d-flex flex-column">
          {copilotb1
            ? copilotb1.map((item, index) => (
                <div
                  key={index}
                  className="copilot_parent_sub_div_tag  d-flex align-items-center"
                >
                  {item.text ? (
                    <span>{item.text}</span>
                  ) : (
                    <img
                      className="coipads_img_tag pt-4"
                      src={item.img}
                      alt="item icon"
                    />
                  )}
                </div>
              ))
            : null}
        </div>

        <div className="copilot_parent_sub_div d-flex flex-column">
          {copilotbox3
            ? copilotbox3.map((item, index) => (
                <div
                  key={index}
                  className="copilot_parent_sub_div_tag  d-flex align-items-center"
                >
                  {item.text ? (
                    <span>{item.text}</span>
                  ) : (
                    <img
                      className="coipads_img_tag"
                      src={item.img}
                      alt="item icon"
                    />
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

const Imayl = () => {
  return (
    <>
      <div
        id="scrollidfour"
        className="imayl_parent-main_div d-flex flex-column align-items-start"
      >
        <span className="Imayl_heading_div">Imayl 9.0</span>
        <div className="d-flex flex-column flex-md-row Imayl_sub_div">
          {imayldata.map((section, sectionIndex) => (
            <div key={sectionIndex} className="text_holder_imayl_comp">
              {section.items.map((item, itemIndex) => (
                <div
                  className="imayl_text_div_tag_items"
                  key={itemIndex}
                  style={{ color: item.color }}
                >
                  {item.text}
                </div>
              ))}
            </div>
          ))}
          <div className="d-flex flex-column align-items-center inbound_mobile_image_text_holder">
            <img className="inbound_mobile_image" src={inbound} />
            <span>
              Streamlining Inbound Package Management with Real-Time Visibility
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

function LandingpageBody({ handleLoadPopup }) {
  return (
    <>
      <TopPagemain handleLoadPopup={handleLoadPopup} />
      <Multicarries />
      <GetstartwithEship handleLoadPopup={handleLoadPopup} />
      <ShipperCopilotSuitev />
      <Eshipjetai />
      <CopilotSix />
      <Rfidnexus />
      <Imayl />
    </>
  );
}

export default LandingpageBody;
export {
  TopPagemain,
  Multicarries,
  Rfidnexus,
  Imayl,
  ShipperCopilotSuitev,
  Eshipjetai,
  CopilotSix,
};
