import React, { useState, useEffect } from "react";
import Data from "../data.json";
import "./Resources.css";
import pdone from "../../Assests/pdone.png";
import pdtwo from "../../Assests/pdtwo.png";
import pdthree from "../../Assests/pdthree.png";
import pdfour from "../../Assests/pdfour.png";
import pdfive from "../../Assests/pdfive.png";
import pdsix from "../../Assests/pdsix.png";
import pdseven from "../../Assests/pdseven.png";
import pdeight from "../../Assests/pdeight.png";

function Resources() {
  const resourcedata = [
    {
      image: pdone,
      heading: "1. Generative AI for Decision Automation",
      subtext:
        "At the heart of eshipjet.ai’s platform is generative AI, which autonomously synthesizes vast amounts of logistics data, enabling the system to anticipate potential challenges, optimize shipping routes, and dynamically adapt to fluctuations in demand. This goes beyond traditional rule-based systems by generating new solutions based on probabilistic models, reducing operational uncertainties.",
    },
    {
      image: pdtwo,
      heading: "2. Reinforcement Learning in Logistics Optimization",
      subtext:
        "eshipjet.ai implements reinforcement learning algorithms to enhance decision-making processes continuously. These algorithms learn optimal policies for various logistics tasks (such as carrier selection or route optimization) through iterative simulations, adapting to both historical data and real-time information.",
    },
    {
      image: pdthree,
      heading: "3. IoT and RFID Integration for Real-Time Data Acquisition",
      subtext:
        "By integrating IoT devices and RFID technology, eshipjet.ai enables precise, real-time data collection across the entire supply chain. This real-time visibility allows for dynamic inventory management, accurate shipment tracking, and predictive maintenance of assets. The platform’s IoT ecosystem is designed to communicate with AI models, creating a feedback loop for continuous optimization.",
    },
    {
      image: pdfour,
      heading: "4. Digital Twin Technology",
      subtext:
        "eshipjet.ai uses digital twins—virtual replicas of physical logistics assets and processes—to simulate and optimize logistics operations before implementing changes in the real world. These digital twins help in predictive analysis, allowing logistics managers to foresee potential disruptions, test various scenarios, and make data-driven decisions.",
    },
    {
      image: pdfive,
      heading:
        "5. Multi-Modal Transport Optimization Using Advanced Algorithms",
      subtext:
        "The platform employs advanced optimization algorithms (e.g., mixed-integer linear programming and heuristic methods) for multi-modal transport solutions, enabling shippers to dynamically switch between air, sea, and land transport. These algorithms factor in variables like cost, time, environmental impact, and carrier performance, ensuring the most efficient logistics decisions.",
    },
    {
      image: pdsix,
      heading: "6. Blockchain for Secure and Transparent Transactions",
      subtext:
        "eshipjet.ai integrates blockchain technology to provide immutable records of transactions across the supply chain. This enhances the security and transparency of logistics operations, particularly in verifying authenticity and reducing fraud in high-value shipments. Smart contracts on blockchain also automate payment processing and compliance checks.",
    },
    {
      image: pdseven,
      heading: "7. AI-Driven Predictive Analysis",
      subtext:
        "The platform uses predictive analytics powered by AI and machine learning models to forecast demand fluctuations, predict potential bottlenecks, and optimize resource allocation. These predictive capabilities enable shippers to stay ahead of market trends and make proactive adjustments, leading to more resilient and adaptive supply chains.",
    },
    {
      image: pdeight,
      heading: "8. Quantum-Inspired Algorithms",
      subtext:
        "eshipjet.ai is exploring the potential of quantum-inspired algorithms for solving complex logistics problems that traditional computers struggle with, such as optimizing multiple variables simultaneously across a global supply chain. These quantum-inspired techniques can significantly accelerate computation times for solving logistics puzzles that involve a high degree of complexity and interdependency.",
    },
  ];


  return (
    <>
      <div className="Resources_page_maind_div">
        <div class="Paradigm_shift_in_main">
          <div class="Paradigm_shift_in_main_text">
            Paradigm shift in logistics industry
          </div>
        </div>

        <div class="paradigm_info_main_div">
          <div class="paradigm_sub_text">
            eshipjet.ai represents a paradigm shift in the logistics industry by
            leveraging cutting-edge technologies such as generative AI, machine
            learning (ML), and Internet of Things (IoT) for end-to-end supply
            chain optimization. The company's core focus lies in transforming
            traditional logistics models into highly adaptive, intelligent
            ecosystems capable of self-optimization and real-time
            decision-making.
          </div>

          <span class="advance_concepts_main_span">
            Advanced Concepts Driving eshipjet
          </span>

          <div class="resource_images_parent" id="resources">
            {resourcedata.map((data, index) => {
              return (
                <div key={index} class="paradigm_person_main_div">
                  <div class="paradigm_person_sub_div">
                    <img
                      class="image_paradigm-resources"
                      src={data.image}
                      alt={data.name}
                    />
                    <div class="paradigm_resource_headings">{data.heading}</div>
                    <div class="paradigm_resource_sub_text">{data.subtext}</div>
                  </div>
                </div>
              );
            })}
          </div>

          <div class="paradigm_sub_text paradigm_harnessing_technologies_div">
            By harnessing these advanced technologies, eshipjet.ai is
            positioning itself as a leader in the next generation of logistics
            innovation. The platform
            <span class="fallback-text">'s</span>
            ability to integrate real-time data, predictive analytics, and
            autonomous decision-making into logistics workflows ensures that
            companies can operate with unprecedented efficiency, scalability,
            and resilience in an increasingly complex global market.
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;
