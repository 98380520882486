import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import popupimg from "../Assests/popupimg.png";
import "./Popup.css";
import emailjs from "emailjs-com";
import { IoIosClose } from "react-icons/io";


const countries = [
  "United States",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];


const Popupinfo = ({ closePopup }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    job_title: "",
    email: "",
    phone_number: "",
    current_challenge: "",
    country: "",
  });

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Validation rules
  const validate = () => {
    const newErrors = {};

    if (!formData.company_name) newErrors.company_name = "Company name is required";
    if (!formData.first_name) newErrors.first_name = "First name is required";
    if (!formData.last_name) newErrors.last_name = "Last name is required";
    if (!formData.job_title) newErrors.job_title = "Job title is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

 
    if (!formData.current_challenge) newErrors.current_challenge = "Current challenge is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    emailjs
      .send("service_kinn8kd", "template_jxl9c13", formData, "VzvkPdSyR8oQM4oyq")
      .then(
        (response) => {
          closePopup();
          navigate('/thankyou');            
        },
        (err) => {
          setSubmissionStatus("Message failed to send. Please try again.");
        }
      );
  };

  return (
    <>
      <div className="parent_popup_main_sub_div d-flex">
        <div className="parent_popup_main_sub_img_div d-flex flex-column ">
          <span className="what_exp">What to expect:</span>
          <li className="wht_exp_sub">End-to-End Shipment Management</li>
          <li className="wht_exp_sub">Real Time Visibility & Tracking</li>
          <li className="wht_exp_sub">Seamless System Integrations</li>
          <li className="wht_exp_sub">Cost Savings up to 30%</li>
          <img className="popup_img" src={popupimg} alt="Popup" />
        </div>

        <div className="formdata_main">

          <div className="formcol">
          <div className="">
            <span className="parent_popup_div_head_text">
              Request a Live Demo
            </span>
            <IoIosClose
              className="closebutton_button_closebtn"
              onClick={closePopup}
            />
          </div>

          {/* Show success message and hide form after successful submission */}
          {isFormSubmitted ? (
            <div className="submission-status success">
              {submissionStatus}
            </div>
          ) : (
            <div className="d-flex flex-column align-items-left h100 cntspacebetween">
              <div className="flex items-center">
              <div className="fullwidth">
              <label>Company Name</label>
              <input
                type="text"
                
                name="company_name"
                required
                onChange={handleChange}
                className="form-control"
              />
              {errors.company_name && <span className="error">{errors.company_name}</span>}
              </div>
              </div>
              <div className="flex items-center">
                <div className="onehalf">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                   
                    required
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.first_name && <span className="error">{errors.first_name}</span>}
                </div>
                <div className="onehalf">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    
                    required
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.last_name && <span className="error">{errors.last_name}</span>}
                </div>
              </div>





              <div className="flex items-center">
                <div className="onehalf">
                  <label>Job Title</label>
                  <input
                    type="text"
                    name="job_title"
                   
                    required
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.job_title && <span className="error">{errors.job_title}</span>}
                </div>
                <div className="onehalf">
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                     
                    required
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
              </div>



              <div className="flex items-center">
                <div className="onehalf">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phone_number"
                    
                    required
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.phone_number && <span className="error">{errors.phone_number}</span>}
                </div>
                <div className="onehalf">
                  <label>Country</label>
                  <select
                    name="country"
                    
                    onChange={handleChange}
                    className="form-select"
                  >
                     <option value="" disabled>
                Select Country
              </option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors.country && <span className="error">{errors.country}</span>}
                </div>
              </div>
              <div className="padt10">
               
              <label>Current Challenges</label>
              <textarea
                name="current_challenge"
                required
                onChange={handleChange}
                className="form-control"
              />
              {errors.current_challenge && <span className="error">{errors.current_challenge}</span>}
              </div> 
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn_submit_form_popupinfo"
              >
              Schedule a demo

              </button>
              




            </div>
          )}
          </div>
        </div>
      </div>
      <div className="shadow"></div>
    </>
  );
};

export default Popupinfo;
